<template>
  <div>
    <PCNavbar ref="navbar" selectIndex="8"/>
    <MobileNavBar selectIndex="8" />
    <div style="padding-top: 100px;">
      <div class = "box">
        <div class="about-title">联系我们</div>
        <div class = "block-line"></div>
        <div class="about-container">
          <p style="line-height:3;text-align:center;margin-top: 10px;">
            <span style="font-size:16px;">电话：83721200、83722708</span> 
          </p>
          <p style="line-height:3;text-align:center;">
            <span style="font-size:16px;">邮箱：dlgtkj@dtspd.com</span> 
          </p>
          <p style="line-height:3;text-align:center;">
            <span style="font-size:16px;">地址：辽宁省大连市中山区人民路23号虹源大厦（3210）室</span> 
          </p>
          <p style="line-height:3;text-align:center;">
            <span style="font-size:16px;">公众号：大连国土空间规划</span> 
          </p>
          <img width="16%" style="margin: 10px 42% 0;margin-bottom: 120px;" src="../assets/htmlimg/qr_weixin.jpeg" alt="" />
        </div>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import PCNavbar from '@/components/navbar/index'
import MobileNavBar from '@/components/navbar/MobileNavBar.vue'
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  components: {
    PCNavbar,
    MobileNavBar,
    CallMe,
    MobileCallme
  },
}
</script>

<style>
</style>